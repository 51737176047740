/** @jsx jsx */
import { jsx } from "theme-ui";
import Layout from "../components/Layout";
import MyStyledLink from "../components/MyStyledLink";

export default function NotFound() {
  return (
    <Layout
      styling={"layout.secondary"}
      additionalStyles={{ maxWidth: "40rem", textAlign: "center" }}
    >
      <h1 sx={{ marginTop: "6rem" }}>Page Not Found</h1>
      <p>Oops, I couldn't find this page!</p>
      <div sx={{ maxWidth: "10rem", m: "auto", height: "2rem" }}>
        <MyStyledLink
          text={"Go home"}
          link={"/about"}
          styling={"styledLinks.primary"}
        />
      </div>
    </Layout>
  );
}
